<template>
  <div class="container">

    <h4 class="text-center my-4">
      Привязка студентов к дисциплине Казахский (русский) язык
    </h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-5">


      <!-- filter -->
      <section>
        <div class="row mb-4">
          <div class="col-md-3">
            Уровень обучения
          </div>
          <div class="col-md-9">
            <Dropdown v-model="studyLevelId" :options="adviserChoose_form.studyLevels" class="w100"
                      optionLabel="name" optionValue="id" placeholder="Выберите уровень обучения"/>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-3">
            Язык обучения
          </div>
          <div class="col-md-9">
            <Dropdown v-model="studyLanguageId" :options="studyLanguages" class="w100"
                      optionLabel="native_name" optionValue="id" placeholder="Выберите язык обучения"/>
          </div>
        </div>


        <div class="row mb-4" v-if="studyLevelId&&studyLanguageId">
          <label class="col-md-3">Образовательная программа</label>
          <div class="col-md-9">
            <MultiSelect v-model="educationProgramIds" :options="educationProgramsWithIdAndName" class="w100"
                         optionLabel="name" optionValue="id" placeholder="Выберите образовательную программу"/>

          </div>
        </div>

        <div v-if="educationProgramIds.length" class="my-4 text-center">
          <Button icon="pi pi-eye" label="Показать" class="p-button-rounded"
                  :loading="loadingStudents" @click="getStudentsByEducationProgramsForKazakhLanguage"/>
        </div>
      </section>
      <!-- end filter -->

      <section v-if="students.length" class="my-5">
        <DataTable :value="students" showGridlines stripedRows responsiveLayout="scroll">
          <Column header="№" :style="{width: '60px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>
          <Column header="Баркод" field="barcode"></Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{data?.last_name}} {{data?.first_name}}
            </template>
          </Column>
          <Column header="Дисциплина" >
            <template #body>
              <Dropdown :options="[{id:'1', name:'11'}]" optionLabel="name" optionValue="id" placeholder="Выберите уровень" class="w100"/>
            </template>
          </Column>
        </DataTable>

        <div v-if="checkedStudents.length" class="text-center my-4">
          <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded"
                  :loading="loadingSaveStudents" @click="saveStudents"/>
        </div>
      </section>

    </div>
  </div>
</template>


<script>

  import {mapActions, mapState} from "vuex"


  export default {
    name: "BindStudentsToKazakhDiscipline",
    data() {
      return {
        loading: true,

        studyLevelId: 0,
        studyLanguageId: 0,
        educationProgramIds: [],

        loadingStudents: false,
        students: [],
        checkedStudents: [],

        loadingSaveStudents: false,

        adviserStudents: [],

        selectStudentsVisible: false
      }
    },
    computed: {
      ...mapState('adviserChoose', ['adviserChoose_form']),
      ...mapState('departmentPractice', ['studyLanguages', 'educationPrograms']),
      educationProgramsWithIdAndName() {
        return this.educationPrograms.map(i => ({
          id: i.id,
          name: `${i.education_speciality_code} ${i.education_speciality_name} - ${i.admission_year}`
        }))
      }
    },
    watch: {
      studyLevelId() {
        this.clearAllData()
      },
      studyLanguageId() {
        this.clearAllData()
      },
    },
    methods: {
      ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
      ...mapActions('departmentPractice', ['GET_STUDY_LANGUAGES', 'GET_EDUCATION_PROGRAMS']),
      ...mapActions('adviser', ['POST_ADVISER_STUDENT', 'GET_ADVISER_STUDENT']),
      ...mapActions('ppsLoad', ['GET_STUDENTS_BY_EDUCATION_PROGRAMS_FOR_KAZAKH_DISCIPLINE']),
      async clearAllData() {
        this.educationProgramIds = []
        this.clearStudents()

        if (this.studyLevelId && this.studyLanguageId) {
          await this.GET_EDUCATION_PROGRAMS({studyLevel: this.studyLevelId, studyLanguage: this.studyLanguageId})
        }
      },
      async getStudentsByEducationProgramsForKazakhLanguage() {
        this.loadingStudents = true

        this.checkedStudents = []
        this.students = await this.GET_STUDENTS_BY_EDUCATION_PROGRAMS_FOR_KAZAKH_DISCIPLINE(this.educationProgramIds)

        this.loadingStudents = false
      },
      clearStudents() {
        this.students = []
      },
      checkAllStudents(e) {
        this.checkedStudents = e.target.checked ? this.students.map(i => i.id) : []
      },
      async saveStudents() {
        this.loadingSaveStudents = true

        const form = this.checkedStudents.map(student_id => ({student_id}))
        const res = await this.POST_ADVISER_STUDENT(form)

        if (res) {
          this.checkedStudents = []
          this.students = []
          this.adviserStudents = await this.GET_ADVISER_STUDENT()
          this.$message({title: 'Успешно сохранено'})
        }

        this.loadingSaveStudents = false
      },
      changeSelectStudentVisible() {
        this.selectStudentsVisible = !this.selectStudentsVisible
      }
    },
    async mounted() {
      this.adviserStudents = await this.GET_ADVISER_STUDENT()

      if (!this.adviserStudents.length) {
        this.selectStudentsVisible = true
      }

      await this.GET_STUDY_LEVELS()
      await this.GET_STUDY_LANGUAGES()

      this.loading = false
    },
  }
</script>

<style scoped>
  .w100 {
    width: 100%;
  }
</style>